<template>
    <div class="contains">
        <top-menu />
        <router-view />
    </div>
</template>

<script setup>
import topMenu from '@/components/top.vue'
import { ref, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
const store = useStore();

// 创建一个新的watcher对象来监听window对象上的resize事件
const resizeHandler = () => {
    // 处理窗口大小改变后的逻辑
    if(window.innerWidth <= 992) {
        store.commit('setPlatform', 'H5');
    } else {
        store.commit('setPlatform', 'PC');
    }
};
onMounted(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
})
onUnmounted(() => {
    window.removeEventListener('resize', resizeHandler);
})
</script>

<style scoped lang="scss">


</style>
