import { createRouter,  createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        redirect: '/',
        children: [
            {
                path: "/",
                name: "Login",
                component: () => import('@/views/login/index.vue'),
                meta: { title: "登录", icon: "page"},
            },
            {
                path: "/register",
                name: "Register",
                component: () => import('@/views/register/index.vue'),
                meta: { title: "注册", icon: "page"},
            },
            {
                path: "/requirements",
                name: "Requirements",
                component: () => import('@/views/requirements/index.vue'),
                meta: { title: "需求发布", icon: "page"},
            },
            {
                path: "/bankCard",
                name: "BankCard",
                component: () => import('@/views/bankCard/index.vue'),
                meta: { title: "银行卡列表", icon: "page"},
            },
            {
                path: "/accountsReceivable",
                name: "AccountsReceivable",
                component: () => import('@/views/accountsReceivable/index.vue'),
                meta: { title: "收款账户", icon: "page"},
            },
            {
                path: "/order",
                name: "Order",
                component: () => import('@/views/order/index.vue'),
                meta: { title: "订单管理", icon: "page"},
            },
            {
                path: "/table",
                name: "Table",
                component: () => import('@/views/table/index.vue'),
                meta: { title: "表格", icon: "page"}
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory("/"),
    routes
})

export default router