<template>
    <div class="top-main">
        <div class="tm-body divFlex">
            <div class="l-left">
                <img class="middle" src="@/assets/images/logo.png" alt="">
                <div class="middle left" v-if="platform == 'PC' && menuInfo.menuShow">
                    <span :class="i == menuInfo.tab?'active':''" v-for="(item,i) in menuInfo.tabList" :key="item.key"  @click="jumpLink(item.key,i)">{{ item.name }}</span>
                </div>
            </div>
            <div class="l-right">
                <el-dropdown :tabindex="1" style="vertical-align: middle;margin:4px 10px 0 0;">
                    <img src="@/assets/images/lang.png" alt="">
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item @click="changeLang('zh','zh_CN')">中文</el-dropdown-item>
                            <el-dropdown-item @click="changeLang('en','en_US')">English</el-dropdown-item>
                            <el-dropdown-item @click="changeLang('sa','hi_IN')">भारतीय</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
                <div class="middle" v-if="platform == 'H5' && menuInfo.menuShow" style="margin-right: 10px;">
                    <el-icon style="vertical-align: middle;" size="30" v-if="!menuInfo.mobileShow" @click="menuInfo.mobileShow = !menuInfo.mobileShow"><Expand /></el-icon>
                    <el-icon style="vertical-align: middle;" size="30" v-else  @click="menuInfo.mobileShow = !menuInfo.mobileShow"><CloseBold /></el-icon>
                </div>
                <div class="middle" v-if="platform == 'PC' && menuInfo.menuShow">
                    <div class="logout" @click="loginOut">{{ $t('menu.m5') }}</div>
                </div>
            </div>
        </div>
        <div class="top-menu" v-if="menuInfo.mobileShow">
            <ul>
                <li :class="i == menuInfo.tab?'active':''" v-for="(item,i) in menuInfo.tabList" :key="item.key"  @click="jumpLink(item.key,i)">{{ item.name }}</li>
                <li @click="loginOut">{{ $t('menu.m5') }}</li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
import { useI18n } from 'vue-i18n'
const i18n = useI18n()
let state = reactive(store.state);
import router from '@/router/index'
// 平台校验
let platform = ref("");
let menuInfo = reactive({
    list: [],
    mobileShow: false,
    tab: 0,
    tabList: [
        {key: "requirements", name: i18n.t('menu.m1')},
        {key: "order", name: i18n.t('menu.m2')},
        {key: "bankCard", name: i18n.t('menu.m3')},
        {key: "accountsReceivable", name: i18n.t('menu.m4')}
    ],
    menuShow: false
})
// 切换语言
function changeLang(e,a) {
    i18n.locale.value = e
    // en_US  hi_IN  zh_CN
    localStorage.setItem('language', a);
    menuInfo.tabList = [
        {key: "requirements", name: i18n.t('menu.m1')},
        {key: "order", name: i18n.t('menu.m2')},
        {key: "bankCard", name: i18n.t('menu.m3')},
        {key: "accountsReceivable", name: i18n.t('menu.m4')}
    ]
}
// 跳转
function jumpLink(e,i) {
    if(e == 'requirements') {
        router.push('/requirements')
    } else if(e == 'accountsReceivable') {
        router.push('/accountsReceivable')
    } else if(e == 'bankCard') {
        router.push('/bankCard')
    } else if(e == 'order') {
        router.push('/order')
    }
    menuInfo.tab = i
    menuInfo.mobileShow = false
    store.commit('setMenuIndex', i)
}
function loginOut() {
    router.push('/')
    menuInfo.tab = 0
    menuInfo.mobileShow = false
    store.commit('setUserInfo', null)
    localStorage.setItem('AutoToken', null)
    store.commit('setMenuIndex', 0)
}
watch(() => [state.platform, state.menuIndex, state.menuShow],(newVal) => {
    platform.value = newVal[0]
    menuInfo.tab = newVal[1]
    menuInfo.menuShow = newVal[2]
})
onMounted(() => {
    platform.value = state.platform
    menuInfo.menuShow = state.menuShow
})
</script>

<style scoped lang="scss">
.top-main{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    box-shadow: 0 5px 10px rgb(0 0 0 / 5%);
    z-index: 99;
    background-color: #fff;
    .tm-body{
        padding:0 0 0 20px;
        line-height: 60px;
        .l-left{
            >img{
                width: 130px;
                height: 35px;
            }
            >.left{
                margin-left: 40px;
                span{
                    font-size: 18px;
                    cursor: pointer;
                    padding: 0 20px;
                    &:hover{
                        color: #1e9fff;
                    }
                    &.active{
                        color: #1e9fff;
                    }
                }
            }
        }
        >.l-right{
            font-size: 0;
            img{
                width: 30px;
            }
            .logout{
                width: 120px;
                line-height: 60px;
                background-color: #06f;
                color: #fff;
                text-align: center;
                font-size: 16px;
                cursor: pointer;
            }
        }
    }
    .top-menu{
        width: 100%;
        background-color: #fff;
        height: calc(100vh - 60px);
        top: 60px;
        border-top: 1px solid #e3e5e7;
        padding: 0 20px 20px;
        li{
            line-height: 50px;
            border-bottom: 1px solid #e3e5e7;
            &:hover{
                color: #1e9fff;
            }
            &.active{
                color: #1e9fff;
            }
        }
    }
}
// @media screen and (max-width: 992px) {
//     .top-main{
//         display: none;
//     }
// }
</style>