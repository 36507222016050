import router from "@/router";
import store from "@/store";

// 路由守卫
router.beforeEach((to, from, next) => {
    if(to.name.includes('Login') || to.name.includes('Register')) {
        store.commit('setMenuShow', false)
    } else {
        store.commit('setMenuShow', true)
    }
    next()
})

router.afterEach((to, from) => {
    
})