import { createStore } from 'vuex'

export default createStore({
    state: {
        platform: "PC",   //  PC  H5
        userInfo: {},   //  用户信息
        menuIndex: 0,  //  菜单路由
        menuShow: false  // 菜单显示与隐藏
    },
    getters: {
        platform: state => state.platform,
        userInfo: state => state.userInfo,
        menuIndex: state => state.menuIndex,
        menuShow: state => state.menuShow
    },
    mutations: {
        setPlatform(state, type) {
            state.platform = type
        },
        setUserInfo(state, type) {
            state.userInfo = type
        },
        setMenuIndex(state, type) {
            state.menuIndex = type
        },
        setMenuShow(state, type) {
            state.menuShow = type
        }
    },
    actions: {
      
    },
    modules: {
    }
  })